import * as React from "react";
import ErrorCardFinePrint from "../ErrorCard/ErrorCardFinePrint/ErrorCardFinePrint";
import ErrorCardButton from "../ErrorCard/ErrorCardButton/ErrorCardButton";
import ErrorCardLcac from "../ErrorCard/ErrorCardLcac/ErrorCardLcac";
import { StateSelector } from "../../../types/general";
import { useError } from "../ErrorCard/useError";
import ErrorCard from "../ErrorCard/ErrorCard";
import ErrorCardCaseNumber from "../ErrorCard/ErrorCardCaseNumber/ErrorCardCaseNumber";
import { useThunkDispatch } from "../../../hooks/thunk";
import { logoutThunk } from "../../../reduxSlices/user";
import { RouteSection, routeString } from "../../../helpers/routes";
import { RouteComponentProps, withRouter } from "react-router";
import { resetAll } from "../../../reduxSlices/rootLevelAction";

const description = "We can't contact our servers right now. Please try signing in later.";

interface Props extends RouteComponentProps {
    errorSelector?: StateSelector<Error | undefined>;
}

const NonRecoverableErrorCard: React.FC<Props> = ({ errorSelector, history }) => {
    //#region hooks
    const error = useError(errorSelector);
    const dispatch = useThunkDispatch();
    //#endregion

    const logout = () => {
        dispatch(resetAll());
        dispatch(logoutThunk);
        history.replace(routeString(RouteSection.SignIn));
    };

    return (
        <ErrorCard>
            <ErrorCardFinePrint>{description}</ErrorCardFinePrint>
            <ErrorCardButton onClick={logout}>okay</ErrorCardButton>
            <ErrorCardCaseNumber error={error} />
            <ErrorCardLcac />
        </ErrorCard>
    );
};

export default withRouter(NonRecoverableErrorCard);
