import { LoadStatus } from "../types/loadStatus";
import { VinOrRegistrationType, VehicleUnitPayload } from "../types/vehicle";
import { StateOrTerritory } from "../types/stateAndTerritory";
import { vehicleUnitUrl, vehicleUnitByVinUrl, associateVehicleUrl } from "../apiHref";
import {
    FetchActionPayload,
    createFetchThunk,
    PromiseThunk,
    fetchActionToLoadStatus,
    FetchAction,
    FetchContainer,
    initialFetchContainer,
    getFetchContainerValue,
    fetchActionPayloadToContainer,
} from "../helpers/fetch";
import { StateSelector } from "../types/general";
import { fetchVehiclesThunk } from "./vehicle";
import { RootLevelAction } from "./rootLevelAction";
import { createSelector } from "reselect";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface RegistrationData {
    registration: string;
    stateOrTerritory?: StateOrTerritory;
}

export interface VinData {
    vin: string;
}

type SetVehicleAssociationUnitPayload = VinOrRegistrationType;
type SetAddTypePayload = VinOrRegistrationType | undefined;
type SetRegistrationDataPayload = RegistrationData;
type SetVinDataPayload = VinData;

type RegistrationVehicleFetchActionPayload = FetchActionPayload<VehicleUnitPayload>;
type VinVehicleFetchActionPayload = FetchActionPayload<VehicleUnitPayload>;
type AssociateVehicleFetchActionPayload = FetchActionPayload<undefined>;

type RegistrationVehicleFetchContainer = FetchContainer<VehicleUnitPayload>;
type VinVehicleFetchContainer = FetchContainer<VehicleUnitPayload>;

export enum AddVehicleFlowAction {
    FetchRegistrationVehicle = "fetchRegistrationVehicle",
    FetchVinVehicle = "fetchVinVehicle",
    ClearAddVehicleFlow = "clearAddVehicleFlow",
    AssociateVehicle = "associateVehicle",
    SetVehicleAssociationUnit = "setVehicleAssociationUnit",
    SetAddType = "setAddType",
    SetRegistrationData = "setRegistrationData",
    ResetRegistrationData = "resetRegistrationData",
    SetVinData = "setVinData",
    SetupVehicleAdd = "setupVehicleAdd",
}

export interface AddVehicleFlowState {
    registrationVehicle: RegistrationVehicleFetchContainer;
    vinVehicle: VinVehicleFetchContainer;
    vehicleAssociation: {
        unit?: VehicleUnitPayload;
        status: LoadStatus;
        by?: VinOrRegistrationType;
        error?: Error;
    };
    addType?: VinOrRegistrationType;
    registrationData: RegistrationData;
    vinData: VinData;
}

export const initialAddVehicleFlowState: AddVehicleFlowState = {
    registrationVehicle: initialFetchContainer,
    vinVehicle: initialFetchContainer,
    vehicleAssociation: {
        status: LoadStatus.NotStarted,
    },
    registrationData: { registration: "" },
    vinData: { vin: "" },
};

export const fetchRegistrationVehicleThunk =
    (registration: string, state: StateOrTerritory): PromiseThunk<VehicleUnitPayload> =>
    async dispatch => {
        const uri = (await vehicleUnitUrl()).replace("{rego}", registration).replace("{state}", state);
        const url = new URL(uri);
        return dispatch(createFetchThunk(url.href, fetchRegistrationVehicle));
    };

export const fetchVinVehicleThunk =
    (vin: string): PromiseThunk<VehicleUnitPayload> =>
    async dispatch => {
        const url = new URL(`${await vehicleUnitByVinUrl()}/${vin}`);
        return dispatch(createFetchThunk(url.href, fetchVinVehicle));
    };

export const associateVehicleThunk =
    (SfId: string, vin: string): PromiseThunk<void> =>
    async dispatch => {
        const url = await associateVehicleUrl();
        const thunk = createFetchThunk(url, associateVehicle, {
            headers: { "Content-Type": "application/json" },
            method: "POST",
            body: JSON.stringify({ vin, SfId }),
        });
        dispatch(thunk).finally(() => {
            dispatch(fetchVehiclesThunk);
            //dispatch(registerGuestThunk(false)); //TODO: find out if we need this
        });
    };

const registrationVehicleContainerSelector: StateSelector<RegistrationVehicleFetchContainer> = state =>
    state.addVehicleFlow.registrationVehicle;

export const registrationVehicleLoadStatusSelector: StateSelector<LoadStatus> = state =>
    registrationVehicleContainerSelector(state).status;

export const registrationVehicleUnitSelector: StateSelector<VehicleUnitPayload | undefined> = createSelector(
    registrationVehicleContainerSelector,
    getFetchContainerValue
);

const vinVehicleContainerSelector: StateSelector<VinVehicleFetchContainer> = state => state.addVehicleFlow.vinVehicle;

export const vinVehicleLoadStatusSelector: StateSelector<LoadStatus> = state =>
    vinVehicleContainerSelector(state).status;

export const vinVehicleUnitSelector: StateSelector<VehicleUnitPayload | undefined> = createSelector(
    vinVehicleContainerSelector,
    getFetchContainerValue
);

export const vehicleAssociationUnitSelector: StateSelector<VehicleUnitPayload | undefined> = state =>
    state.addVehicleFlow.vehicleAssociation.unit;

export const vehicleAssociationStatusSelector: StateSelector<LoadStatus> = state =>
    state.addVehicleFlow.vehicleAssociation.status;

export const vehicleAssociationBySelector: StateSelector<VinOrRegistrationType | undefined> = state =>
    state.addVehicleFlow.vehicleAssociation.by;

export const addTypeSelector: StateSelector<VinOrRegistrationType | undefined> = state => state.addVehicleFlow.addType;

export const registrationDataSelector: StateSelector<RegistrationData> = state => state.addVehicleFlow.registrationData;

export const vinDataSelector: StateSelector<VinData> = state => state.addVehicleFlow.vinData;

export const vehicleErrorSelector: StateSelector<Error | undefined> = state =>
    state.addVehicleFlow.vehicleAssociation.error;

const vehicleFlowSlice = createSlice({
    name: "vehicleFlow",
    initialState: initialAddVehicleFlowState,
    reducers: {
        [AddVehicleFlowAction.FetchRegistrationVehicle]: (
            state,
            action: PayloadAction<RegistrationVehicleFetchActionPayload>
        ) => {
            state.registrationVehicle = fetchActionPayloadToContainer(action.payload);
        },
        [AddVehicleFlowAction.FetchVinVehicle]: (state, action: PayloadAction<VinVehicleFetchActionPayload>) => {
            state.vinVehicle = fetchActionPayloadToContainer(action.payload);
        },
        [AddVehicleFlowAction.ClearAddVehicleFlow]: () => initialAddVehicleFlowState,
        [AddVehicleFlowAction.AssociateVehicle]: (state, action: PayloadAction<AssociateVehicleFetchActionPayload>) => {
            state.vehicleAssociation.status = fetchActionToLoadStatus(action.payload.action);
            state.vehicleAssociation.error =
                action.payload.action === FetchAction.Failure ? action.payload.value : undefined;
        },
        [AddVehicleFlowAction.SetVehicleAssociationUnit]: (
            state,
            action: PayloadAction<SetVehicleAssociationUnitPayload>
        ) => {
            const unit =
                action.payload === VinOrRegistrationType.VIN
                    ? getFetchContainerValue(state.vinVehicle as VinVehicleFetchContainer)
                    : action.payload === VinOrRegistrationType.REGISTRATION
                    ? getFetchContainerValue(state.registrationVehicle as RegistrationVehicleFetchContainer)
                    : undefined;

            state.vehicleAssociation.status = LoadStatus.NotStarted;
            state.vehicleAssociation.unit = unit;
            state.vehicleAssociation.by = action.payload;
        },
        [AddVehicleFlowAction.SetAddType]: (state, action: PayloadAction<SetAddTypePayload>) => {
            state.addType = action.payload;
        },
        [AddVehicleFlowAction.SetRegistrationData]: (state, action: PayloadAction<SetRegistrationDataPayload>) => {
            state.registrationData = action.payload;
        },
        [AddVehicleFlowAction.ResetRegistrationData]: state => {
            state.registrationData = initialAddVehicleFlowState.registrationData;
            state.registrationVehicle = { ...initialAddVehicleFlowState.registrationVehicle };
        },
        [AddVehicleFlowAction.SetVinData]: (state, action: PayloadAction<SetVinDataPayload>) => {
            state.vinData = action.payload;
        },
        [AddVehicleFlowAction.SetupVehicleAdd]: state => {
            state.registrationVehicle = initialAddVehicleFlowState.registrationVehicle;
        },
        [RootLevelAction.Reset]: () => initialAddVehicleFlowState,
    },
});

export const {
    fetchRegistrationVehicle,
    fetchVinVehicle,
    setAddType,
    setRegistrationData,
    resetRegistrationData,
    setVinData,
    associateVehicle,
    clearAddVehicleFlow,
    setVehicleAssociationUnit,
    setupVehicleAdd,
} = vehicleFlowSlice.actions;

export const { reducer: vehicleFlowReducer } = vehicleFlowSlice;
