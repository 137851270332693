import * as React from "react";
import { useSelector, useStore } from "react-redux";
import {
    bookingInProgressDropOffDateTimeSelector,
    bookingInProgressPickUpDateTimeSelector,
    currentBookingSelector,
} from "../../../reduxSlices/onDemand/onDemandSelectors";
import { CurrentBooking } from "../../../reduxSlices/onDemand/onDemandInterfaces";
import { makeBookingRoute, RouteSection, routeString, toastUrlFromPath } from "../../../helpers/routes";
import { useThunkDispatch } from "../../../hooks/thunk";
import { RouteComponentProps } from "react-router";
import { epochAndOffsetToDate, isDateTimeOutsideOriginalDateTime } from "../../../helpers/dateTime";
import { resetSelectedVehicle } from "../../../reduxSlices/onDemand/onDemandReducers";

const currentBookingBaseRoute = (currentBooking: CurrentBooking) =>
    routeString(RouteSection.Encore, RouteSection.Bookings, currentBooking.bookingReference as RouteSection);

export interface CurrentBookingProps {
    baseRoute: string;
    continueButtonTitle: string;
    vehicleUnavailableHandler: () => void;
}

const withCurrentBooking =
    <P extends RouteComponentProps>(Component: React.ComponentType<P & CurrentBookingProps>): React.ComponentType<P> =>
    props => {
        const store = useStore();
        const currentBooking = useSelector(currentBookingSelector);
        const dispatch = useThunkDispatch();

        const makeBookingProps = (): CurrentBookingProps => ({
            baseRoute: makeBookingRoute,
            continueButtonTitle: "continue",
            vehicleUnavailableHandler: () => {
                dispatch(resetSelectedVehicle());
                props.history.push(makeBookingRoute);
            },
        });

        const getBookingProps = (): CurrentBookingProps => {
            return currentBooking
                ? {
                      baseRoute: currentBookingBaseRoute(currentBooking),
                      continueButtonTitle: "change",
                      vehicleUnavailableHandler: () => {
                          const pickUpDateTime = bookingInProgressPickUpDateTimeSelector(store.getState());
                          const dropOffDateTime = bookingInProgressDropOffDateTimeSelector(store.getState());
                          const isOutsideOriginal = isDateTimeOutsideOriginalDateTime(
                              {
                                  start: pickUpDateTime,
                                  end: dropOffDateTime,
                              },
                              {
                                  start: epochAndOffsetToDate(currentBooking.start, currentBooking.startOffset),
                                  end: epochAndOffsetToDate(currentBooking.end, currentBooking.endOffset),
                              }
                          );
                          if (isOutsideOriginal) {
                              dispatch(resetSelectedVehicle());
                              props.history.push(toastUrlFromPath(RouteSection.VehicleUnavailable, location.pathname));
                          } else {
                              props.history.push(currentBookingBaseRoute(currentBooking));
                          }
                      },
                  }
                : makeBookingProps();
        };

        const bookingProps = getBookingProps();
        return <Component {...bookingProps} {...props} />;
    };

export default withCurrentBooking;
