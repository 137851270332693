import "./Header.scss";
import * as React from "react";
import { CollapseState, mobileSecondaryNavigationOpenSelector } from "../../reduxSlices/general";
import HeaderPrimaryAndSecondary from "./HeaderPrimaryAndSecondary/HeaderPrimaryAndSecondary";
import classnames from "classnames";
import { createBemFn } from "lexus-style-guide/utilities/bem";
import { useSelector } from "react-redux";

const bem = createBemFn("header");

interface HeaderProps {
    hideBackground?: boolean;
}

const Header: React.FunctionComponent<React.PropsWithChildren<HeaderProps>> = ({
    children,
    hideBackground = false,
}) => {
    const mobileSecondaryNavigationOpen = useSelector(mobileSecondaryNavigationOpenSelector);
    const mobileSecondaryNavigationShowHeaderBackground =
        mobileSecondaryNavigationOpen === CollapseState.Opening || mobileSecondaryNavigationOpen === CollapseState.Open;

    return (
        <div
            className={classnames(bem(), {
                [bem(undefined, "hide-background")]: hideBackground && !mobileSecondaryNavigationShowHeaderBackground,
            })}
        >
            <HeaderPrimaryAndSecondary secondaryNavigation />
            {children}
        </div>
    );
};

export default Header;
